(function(){
	'use strict';

	angular.module('aerosApp')
		.directive('resultLength', [function () {
			return {
				restrict: 'A',
				scope: {
					fiberGroup: "=",
					result: "="
				},
				templateUrl: '/static/templates/directive/result-length.html',
				controller: ['$scope', '$element', function ($scope, $element) {
					var fiberGroup = $scope.fiberGroup;
					var result = $scope.result;

					if (result.lengthMeasurement) {
						var lengthMeasurement = result.lengthMeasurement;
						var failed = lengthMeasurement.status == 'Failed';
						var passed = lengthMeasurement.status == 'PassedOrComplete';

						$element.toggleClass("Failed", failed);
						$element.toggleClass("PassedOrComplete", passed);

						$scope.value = lengthMeasurement.roundedLength;
						$scope.mantissa = 0;
					}

					$scope.shouldHave = fiberGroup.requiresLength && !result.lengthMeasurement;
				}
			]};
		}]);
})();